
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import debounce from 'lodash-es/debounce';
import {
  BaseButton,
  BasePopover,
  PopoverButton,
  BasePagination,
  ReviewSummary,
  SearchInput
} from '@/lib/components';
import PatientConsultsDataTable from '@/lib/components/DataTable/PatientConsultsDataTable.vue';
import PatientConsultsModalWrapper from '@/lib/components/Modals/PatientConsultsModalsWrapper.vue';

import {
  ClinicConfigurationsService,
  ConsultService,
  PatientConsultService,
  PatientFormSubmissionService,
  PatientReviewService
} from '@/services/api';
import {
  Action,
  Clinic,
  ClinicConfiguration,
  Consult,
  PaginatedResponse,
  Patient,
  PatientConsultResponseData,
  PatientConsultTableData,
  RejectReasonValue,
  ResolveReasonValue
} from '@/models';
import { isMajorPatient } from '@/helpers/patient.helper';
import { IErrors } from '@/lib';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  components: {
    SearchInput,
    ReviewSummary,
    BaseButton,
    BasePagination,
    BasePopover,
    PopoverButton,
    PatientConsultsDataTable,
    PatientConsultsModalWrapper
  }
})
export default class PatientConsultsPage extends Vue {
  patient!: Patient;
  errors: { [key: string]: Array<string> } = {};
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  // Clinic Configurations
  loadingClinicConfigurations = false;
  clinicConfigurations: ClinicConfiguration[] = [];

  clinicConfigurationId = '';
  // Consults
  rejectedConsultId = '';
  rejectReasonError: string | null = null;
  resolvedConsultId = '';
  resolveReasonError: string | null = null;
  perPage = 10;
  total = 0;
  search = '';
  rows: PatientConsultTableData[] = [];
  formSubmissionService = new PatientFormSubmissionService(this.patient.id);
  consultService = new ConsultService();
  patientConsultService = new PatientConsultService(this.patient.id);
  viewLetter = false;
  assignConsultModalVisible = false;
  rejectedReasonModalVisible = false;
  resolveReasonModalVisible = false;
  scheduleConsultModalVisible = false;
  viewAndCopyReviewSummaryVisible = false;

  selectedDocumentId: number | null = null;
  // MRN
  canEditPatientMrn = true;

  // View and copy ReviewSummary in plain test
  currentConsult: Consult | null = null;

  selectedPatient: Patient | null = this.patient;
  selectedReviewId = '';
  selectedFormSubmissionId = '';
  selectedClinic: Clinic = { id: '', name: '', clinic_configuration_id: '' };
  selectedConsultCompletedAt = '';
  selectedConsultId = '';

  selectedFormSubmissionClinician = '';

  mounted() {
    this.search = String(this.$route.query.search || '');
    this.fetchClinicConfigurations();
    this.fetchConsults();

    this.$watch('currentOrganisationId', () => {
      this.search = String(this.$route.query.search || '');
      this.fetchClinicConfigurations();
    });
    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchConsults();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  get currentOrganisationId(): string | null {
    return this.sessionStore.currentOrganisationId;
  }

  get clinicConfigurationService(): ClinicConfigurationsService | null {
    return this.currentOrganisationId
      ? new ClinicConfigurationsService(this.currentOrganisationId)
      : null;
  }

  get clinicTypeOptions() {
    return this.clinicConfigurations.map((clinicConfiguration) => ({
      value: clinicConfiguration.id,
      label: clinicConfiguration.name
    }));
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'created.time';
  }

  get isMajorPatient(): boolean {
    return isMajorPatient(this.patient);
  }

  async fetchConsults() {
    try {
      this.loadingConsults = true;
      const response = (await this.patientConsultService.index({
        params: {
          page: this.page,
          sort: this.sort,
          perPage: this.perPage,
          include: 'review',
          ...(this.search ? { 'filter[search]': this.search } : {})
        }
      })) as PaginatedResponse<PatientConsultResponseData[]>;

      // Rejected consults should not appear in the PatientConsultsPage
      this.rows = response.data.map((row: PatientConsultResponseData) => ({
        ...row,
        location: row.location ? row.location.name : null,
        scheduled_location: {
          location: row.scheduled_location,
          title: row.clinic_provider_title
        },
        correspondence: row.status,
        action: {
          id: row.id,
          status: row.status,
          formId: row.form_id,
          formSubmissionId: row.form_submission_id,
          locked: row.review ? row.review.locked : false,
          lockedByUserFullName: row.review
            ? row.review.locked_by_user_full_name
            : '',
          fileAttachmentId: row.file_attachment_id
        }
      }));
      this.total = response.meta.total;
    } catch (error: any) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.fetch-error'),
        label: error.response?.data?.message
      });
    } finally {
      this.loadingConsults = false;
    }
  }

  async fetchClinicConfigurations() {
    if (this.clinicConfigurationService) {
      try {
        this.loadingClinicConfigurations = true;
        this.clinicConfigurations = (
          await this.clinicConfigurationService.index()
        ).data;
      } catch (e) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.fetch-data')
        });
      } finally {
        this.loadingClinicConfigurations = false;
      }
    }
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  // Rejected Modal
  async openRejectReasonModal(id: string) {
    this.rejectedConsultId = id;
    this.rejectedReasonModalVisible = true;
    await this.fetchConsult(id);
  }

  closeRejectReasonModal() {
    this.rejectedConsultId = '';
    this.rejectedReasonModalVisible = false;
    this.currentConsult = null;
  }

  // Resolve Modal
  async openResolveReasonModal(id: string) {
    this.resolvedConsultId = id;
    this.resolveReasonModalVisible = true;
    await this.fetchConsult(id);
  }

  closeResolveReasonModal() {
    this.resolvedConsultId = '';
    this.resolveReasonModalVisible = false;
    this.currentConsult = null;
  }

  showAssignConsultModal(id: string) {
    this.assignConsultModalVisible = true;
    this.clinicConfigurationId = id;
  }

  viewSupportingDocument(action: Action) {
    this.selectedDocumentId = action.fileAttachmentId;
  }

  // Support Document

  closeSupportingDocumentModal() {
    this.selectedDocumentId = null;
  }

  // Schedule Consult Modal
  closeScheduleConsultModal() {
    this.scheduleConsultModalVisible = false;
    this.clinicConfigurationId = '';
    this.fetchConsults();
  }

  // Assign Consult Modal

  closeAssignConsultModal() {
    this.assignConsultModalVisible = false;
    this.selectedConsultId = '';
    this.currentConsult = null;
    this.fetchConsults();
  }

  async startConsult(action: Action) {
    this.loadingAction = true;
    try {
      const response = await this.formSubmissionService.create({
        form_id: action.formId,
        organisational_unit_id: this.currentOrganisationId,
        consult_id: action.id
      });
      await this.$router.push(
        `/patients/${this.patient.id}/forms/${response.data.id}`
      );
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: `${this.$t('platform.error.error')}: ${e.message}`
      });
      this.loadingAction = false;
    }
  }

  // Events

  resumeConsult(action: Action) {
    this.$router.push(
      `/patients/${this.patient.id}/forms/${action.formSubmissionId}`
    );
  }

  async showScheduleConsultModal(id: string) {
    this.selectedConsultId = id;
    this.scheduleConsultModalVisible = true;
    await this.fetchConsult(this.selectedConsultId);
  }

  startReview(action: Action) {
    this.$router.push(
      `/patients/${this.patient.id}/consults/${action.id}/image-review`
    );
  }

  viewReviewSummary(action: Action) {
    this.openViewAndCopyReviewSummaryModel(action);
  }

  async viewPatientLetter(action: Action) {
    const consult = await this.consultService.fetch(action.id);
    this.viewLetter = true;
    this.selectedReviewId = consult.review?.id || '';
  }

  closeLetter(error: any) {
    this.viewLetter = false;
    this.selectedReviewId = '';
    if (error) {
      this.notificationStore.addErrorNotification({
        title: 'Error while fetching letter'
      });
    }
  }

  closeViewAndCopyReviewSummaryModel() {
    this.viewAndCopyReviewSummaryVisible = false;
    this.selectedReviewId = '';
    this.selectedFormSubmissionId = '';
  }

  async openViewAndCopyReviewSummaryModel(action: Action) {
    const consult = await this.consultService.fetch(action.id);
    this.currentConsult = consult;
    this.selectedReviewId = consult.review?.id || '';
    this.selectedClinic = consult?.clinic;
    if (consult.completed_at) {
      this.selectedConsultCompletedAt = consult.completed_at;
    }
    if (consult.form_submission && consult.clinician) {
      this.selectedFormSubmissionId = consult.form_submission.id;
      this.selectedFormSubmissionClinician = consult.clinician;
    }
    this.viewAndCopyReviewSummaryVisible = true;
  }

  clickUnlockConsult(
    patientId: string,
    reviewId: string,
    lockedByUserFullName: string
  ) {
    this.modalTargetPatientId = patientId;
    this.modalTargetReviewId = reviewId;
    this.modalTargetLockedByUserFullName = lockedByUserFullName;
    this.isModalOpen = true;
  }

  async unlockConsult(patientId: string, reviewId: string) {
    const patientReviewService = new PatientReviewService(patientId);
    this.isModalOpen = false;
    await patientReviewService.unlock(reviewId);
    return this.fetchConsults();
  }

  async markAsRejected(rejectedReason: RejectReasonValue) {
    try {
      const data = {
        rejected_at: dayjs().utc().toISOString(),
        rejected_reason_type: rejectedReason.selectedReason,
        rejected_reason: rejectedReason.additionalReason
      };
      await this.consultService.update(this.rejectedConsultId, data);
      await this.fetchConsults();
      this.closeRejectReasonModal();
    } catch (e: any) {
      if (e.response.status === 422) {
        this.rejectReasonError =
          this.extractErrorMessageFromRejectResponseErrors(
            e.response.data.errors
          );
      }
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.update-error')
      });
    }
  }

  async markAsResolved(resolveReason: ResolveReasonValue) {
    try {
      const data = {
        resolved_rejected_at: dayjs().utc().toISOString(),
        resolved_reason_type: resolveReason.selectedReason,
        resolved_reason: resolveReason.additionalReason
      };
      await this.consultService.update(this.resolvedConsultId, data);
      await this.fetchConsults();
      this.closeResolveReasonModal();
    } catch (e: any) {
      if (e.response.status === 422) {
        this.resolveReasonError =
          this.extractErrorMessageFromResolveResponseErrors(
            e.response.data.errors
          );
      }
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.update-error')
      });
    }
  }

  extractErrorMessageFromRejectResponseErrors(errors: IErrors): string | null {
    let errorMessages: Array<string> = [];
    if (errors.rejected_reason) {
      errorMessages = errorMessages.concat(errors.rejected_reason);
    }
    if (errors.rejected_reason_type) {
      errorMessages = errorMessages.concat(errors.rejected_reason_type);
    }
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join(' ');
  }

  extractErrorMessageFromResolveResponseErrors(errors: IErrors): string | null {
    let errorMessages: Array<string> = [];
    if (errors.resolved_reason) {
      errorMessages = errorMessages.concat(errors.resolved_reason);
    }
    if (errors.resolved_reason_type) {
      errorMessages = errorMessages.concat(errors.resolved_reason_type);
    }
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join(' ');
  }

  // Resend Patient Letter
  async resendPatientLetter(patientId: string, reviewId: string) {
    try {
      const patientReviewService = new PatientReviewService(patientId);
      await patientReviewService.resend(reviewId, this.currentOrganisationId);

      await this.notificationStore.addSuccessNotification({
        title: this.$t('custom.uhb.correspondence.resend-successful')
      });
    } catch (exception: any) {
      if (exception.response.status === 400) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.correspondence.resend-failed.title'),
          label: this.$t('custom.uhb.correspondence.resend-failed.label')
        });
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.correspondence.resend-failed.title')
        });
      }
    }
  }

  async fetchConsult(consultId: string) {
    try {
      this.currentConsult = await this.consultService.fetch(consultId, {
        params: {
          include: 'patient,clinic,clinicConfiguration,review,form_submissions'
        }
      });

      if (this.currentConsult?.patient) {
        this.selectedPatient = this.currentConsult.patient;

        // Disable the patient MRN input if the MRN is already set
        if (this.currentConsult.patient_mrn_at_clinic_provider) {
          this.canEditPatientMrn = false;
        }

        this.patient_mrn_at_clinic_provider =
          this.currentConsult.patient_mrn_at_clinic_provider;
      }
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }
}
