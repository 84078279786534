import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between pb-8 border-b" }
const _hoisted_4 = { class: "flex items-center text-lg font-semibold text-gray-900" }
const _hoisted_5 = { class: "flex flex-row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_PatientConsultsDataTable = _resolveComponent("PatientConsultsDataTable")!
  const _component_PatientConsultsModalWrapper = _resolveComponent("PatientConsultsModalWrapper")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.consult.consults')), 1),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_SearchInput, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            size: "small",
            class: "w-48 text-sm",
            icon: "search",
            onChange: _ctx.updateSearchDebounced
          }, null, 8, ["modelValue", "onChange"]),
          _createVNode(_component_BasePopover, { position: "left" }, {
            button: _withCtx(() => [
              (_ctx.isMajorPatient && _ctx.clinicTypeOptions.length > 0)
                ? (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 0,
                    class: "ml-4",
                    "left-icon": "add",
                    type: "button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.consult.assign')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            menu: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clinicTypeOptions, (clinicType) => {
                return (_openBlock(), _createBlock(_component_PopoverButton, {
                  key: clinicType.value,
                  onClick: ($event: any) => (_ctx.showAssignConsultModal(clinicType.value))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(clinicType.label), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ])), [
          [_directive_allow, 'consult:create']
        ])
      ]),
      _createVNode(_component_PatientConsultsDataTable, {
        patient: _ctx.patient,
        rows: _ctx.rows,
        "per-page": _ctx.perPage,
        page: _ctx.page,
        total: _ctx.total,
        search: _ctx.search,
        "view-letter": _ctx.viewLetter,
        onShowScheduleConsultModal: _ctx.showScheduleConsultModal,
        onStartConsult: _ctx.startConsult,
        onResumeConsult: _ctx.resumeConsult,
        onStartReview: _ctx.startReview,
        onViewReviewSummary: _ctx.viewReviewSummary,
        onOpenRejectedReasonModal: _ctx.openRejectReasonModal,
        onOpenResolveReasonModal: _ctx.openResolveReasonModal,
        onResendPatientLetter: _ctx.resendPatientLetter,
        onViewSupportingDocument: _ctx.viewSupportingDocument,
        onViewPatientLetter: _ctx.viewPatientLetter,
        onClickUnlockConsult: _ctx.clickUnlockConsult
      }, null, 8, ["patient", "rows", "per-page", "page", "total", "search", "view-letter", "onShowScheduleConsultModal", "onStartConsult", "onResumeConsult", "onStartReview", "onViewReviewSummary", "onOpenRejectedReasonModal", "onOpenResolveReasonModal", "onResendPatientLetter", "onViewSupportingDocument", "onViewPatientLetter", "onClickUnlockConsult"])
    ]),
    _createVNode(_component_PatientConsultsModalWrapper, {
      "clinic-type-options": _ctx.clinicTypeOptions,
      consult: _ctx.currentConsult,
      patient: _ctx.selectedPatient,
      "can-edit-patient-mrn": _ctx.canEditPatientMrn,
      "view-letter": _ctx.viewLetter,
      "clinic-configuration-id": _ctx.clinicConfigurationId,
      "assign-consult-modal-visible": _ctx.assignConsultModalVisible,
      "rejected-reason-modal-visible": _ctx.rejectedReasonModalVisible,
      "resolve-reason-modal-visible": _ctx.resolveReasonModalVisible,
      "schedule-consult-modal-visible": _ctx.scheduleConsultModalVisible,
      "view-and-copy-review-summary-visible": _ctx.viewAndCopyReviewSummaryVisible,
      "selected-review-id": _ctx.selectedReviewId,
      "selected-document-id": _ctx.selectedDocumentId,
      "consult-completed-at": _ctx.selectedConsultCompletedAt,
      "selected-form-submission-id": _ctx.selectedFormSubmissionId,
      "selected-form-submission-clinician": _ctx.selectedFormSubmissionClinician,
      "selected-clinic": _ctx.selectedClinic,
      "reject-reason-error": _ctx.rejectReasonError,
      "resolve-reason-error": _ctx.resolveReasonError,
      onCloseRejectedReasonModal: _ctx.closeRejectReasonModal,
      onCloseResolveReasonModal: _ctx.closeResolveReasonModal,
      onCloseScheduleConsultModal: _ctx.closeScheduleConsultModal,
      onCloseAssignConsultModal: _ctx.closeAssignConsultModal,
      onShowScheduleConsultModal: _ctx.showScheduleConsultModal,
      onCloseViewAndCopyReviewSummaryModel: _ctx.closeViewAndCopyReviewSummaryModel,
      onUpdatePatient: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update-patient'))),
      onUpdateRejectErrorMessage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rejectReasonError = $event)),
      onUpdateResolveErrorMessage: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resolveReasonError = $event)),
      onMarkAsRejected: _ctx.markAsRejected,
      onMarkAsResolved: _ctx.markAsResolved,
      onCloseLetter: _ctx.closeLetter
    }, null, 8, ["clinic-type-options", "consult", "patient", "can-edit-patient-mrn", "view-letter", "clinic-configuration-id", "assign-consult-modal-visible", "rejected-reason-modal-visible", "resolve-reason-modal-visible", "schedule-consult-modal-visible", "view-and-copy-review-summary-visible", "selected-review-id", "selected-document-id", "consult-completed-at", "selected-form-submission-id", "selected-form-submission-clinician", "selected-clinic", "reject-reason-error", "resolve-reason-error", "onCloseRejectedReasonModal", "onCloseResolveReasonModal", "onCloseScheduleConsultModal", "onCloseAssignConsultModal", "onShowScheduleConsultModal", "onCloseViewAndCopyReviewSummaryModel", "onMarkAsRejected", "onMarkAsResolved", "onCloseLetter"])
  ]))
}